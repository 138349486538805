import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Compliance Stage`}</h2>
    <p>{`The Compliance Fee of £75.00 is raised when we receive your case from the Council.`}</p>
    <p>{`The Compliance Stage provides you with the opportunity to make contact with us to make full payment, or to discuss a suitable arrangement to pay by instalments.`}</p>
    <p>{`An Enforcement Agent will not visit your property during this stage.`}</p>
    <p>{`We must issue you a Notice of Enforcement and allow at least 7 clear days from the issue of this Notice before we visit you.`}</p>
    <p>{`The Compliance Fee is charged on each case we receive for you, even if they are received at the same time.`}</p>
    <h2>{`Enforcement Stage`}</h2>
    <p>{`The Enforcement Stage Fee is £235.00 and this is raised upon the first visit to your premises by an
Enforcement Agent.`}</p>
    <p>{`Your case will have progressed to the Enforcement Stage either because you have failed to contact us during the Compliance Stage, or because you have defaulted on agreed payments.`}</p>
    <p>{`For larger balances and multiple cases, please note an additional fee of 7.5% of the debt over £1,500 is chargeable on top of the £235.00.`}</p>
    <h2>{`Sale Stage`}</h2>
    <p>{`The Sale Fee is £110.00 and is raised when we have taken control of your goods and have attended to arrange for those goods to be removed.`}</p>
    <p>{`For larger balances and multiple cases, please note an additional fee
of 7.5% of the debt over £1,500 is charged on top of the £110.00.`}</p>
    <h2>{`Further Information`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.legislation.gov.uk/ukpga/2007/15/schedule/12"
      }}>{`Schedule 12, Tribunals, Courts & Enforcement Act 2007`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.legislation.gov.uk/uksi/2013/1894/contents/made"
      }}>{`The Taking Control of Goods Regulations 2013`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.legislation.gov.uk/uksi/2014/1/contents/made"
      }}>{`The Taking Control of Goods (Fees) Regulations 2014`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.civea.co.uk/our-code-of-practice"
      }}>{`CIVEA Code of Practice`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/353396/taking-control-of-goods-national-standards.pdf"
      }}>{`Taking Control of Goods: National Standards`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      